import React from "react";
import { get, attempt } from "lodash";
import { AppRegistry } from "react-native";
import { loadableReady } from "@loadable/component";

import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import {
  TvgConfProvider,
  TVGThemeProviderWeb,
  ToastManager,
  useMediaQuery,
  breakpoints
} from "@tvg/design-system";
import { FormationTokens } from "@tvg/design-system/web";
import { TvgSafeAreaProvider } from "@tvg/design-system/src/utils/tvgSafeAreaProvider";
import ApolloContext from "@tvg/utils/apolloContext";
import axios from "axios";
import tvgConf from "@tvg/conf";
import "@tvg/braze";
import "@tvg/gtm";
import sendToAppsFlyer from "@tvg/utils/appsflyerUtils";
import "@tvg/perimeterx/perimeterx";
import { configureStore } from "../shared/store/configureStore";
import broadcastActions from "../shared/store/broadcastActions";
// @ts-ignore
import ApolloClient from "../shared/apolloClient/ApolloClient";
import App from "../shared";
// Import code to inject datadog to the window
import "../../utils/injectDatadog";
// detect private mode
import "../../utils/checkPrivateMode";
import { GlobalStyle } from "../shared/layouts/MainLayout/styled-components";

// gets conf for specific host / device
const tvg = tvgConf(window.location.hostname, window.navigator.userAgent);

// this closes the window if it was opened programmatically
// in this case its the return url from MZM deposit
if (typeof window !== "undefined" && window.location.hash === "#closeTab") {
  window.localStorage.setItem("isMzmTabClosed", "true");
  window.close();
}

// middleware to broadcast redux actions between windows/tabs
const broadcastMiddleware = broadcastActions("desk_broadcast_actions");

// gets server side generated initial redux store
const store =
  // @ts-ignore
  window.store ||
  configureStore({
    initialState: window.__DESKTOP_PRELOADED_STATE__, // eslint-disable-line
    middlewares: [broadcastMiddleware]
  });

// @ts-ignore
window.onerror = (msg, url, line, column, error) => {
  const accountId = attempt(() => sessionStorage.getItem("userId"));
  if (msg && msg !== "Script error.") {
    axios({
      url: "/logerror",
      method: "post",
      withCredentials: true,
      data: {
        msg,
        error: error && error.stack,
        accountId,
        tvgContext: tvg.context()
      }
    });
  }

  return false;
};

if (process.env.NODE_ENV === "development") {
  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept();
  }

  // @ts-ignore
  if (!window.store) {
    // @ts-ignore
    window.store = store;
  }
}

// Send AppsFlyer app launch event for iOS app
const didAppLaunch = sessionStorage.getItem("didAppLaunch");
if (!didAppLaunch) {
  sendToAppsFlyer({
    key: "af_app_launch"
  });
  sessionStorage.setItem("didAppLaunch", "true");
}

const enableCosmoGraph = get(
  window,
  "__DESKTOP_PRELOADED_STATE__.capi.featureToggles.useCosmoGraph",
  false
);

const defaultClient = ApolloClient.createClient(
  false,
  "graph",
  enableCosmoGraph
);
const fcpClient = ApolloClient.createClient(false, "fcp");
const rdaClient = ApolloClient.createClient(false, "rda");
const gasClient = ApolloClient.createClient(false, "gas");
const behgClient = defaultClient;
// TODO: remove this once we have a the new theme
// const isFanduelTheme = useFanduelTheme();
const isFanduelTheme = false;

const Main = () => {
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);

  return (
    <TvgConfProvider>
      <TVGThemeProviderWeb isFanduelTheme={isFanduelTheme}>
        <TvgSafeAreaProvider>
          <>
            <FormationTokens />
            <GlobalStyle />
            <ApolloProvider client={defaultClient}>
              <ApolloContext.Provider
                value={{
                  fcpClient,
                  rdaClient,
                  gasClient,
                  behgClient
                }}
              >
                <Provider store={store}>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </Provider>
              </ApolloContext.Provider>
            </ApolloProvider>
            <ToastManager
              qaLabel="toast-manager"
              variant="informational"
              hasRoundedStyle={isMobile}
              message="default message!"
              ref={(ref) => ToastManager.setRef(ref)}
            />
          </>
        </TvgSafeAreaProvider>
      </TVGThemeProviderWeb>
    </TvgConfProvider>
  );
};

loadableReady(() => {
  AppRegistry.registerComponent("App", () => Main);

  AppRegistry.runApplication("App", {
    initialProps: {},
    rootTag: document.getElementById("app")
  });
});
