import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import useSeoTracks from "@urp/lib-racetracks/src/hooks/useSeoTracks";
import { useNavigate } from "react-router";
import { SeoTrack } from "@tvg/ts-types/Track";
import { formatTrackName } from "@tvg/formatter/url";
import { DialogMessage } from "@tvg/design-system";
import { tabsTracksPage } from "@urp/lib-racetracks/src/redux/defaultValues";
import { TRACKS_PATHS, useFavoriteWithRedux } from "@urp/lib-racetracks";
import { getAccountNumber, getIsLogged } from "@urp/store-selectors";
import { getTabsTracksPage } from "@urp/lib-racetracks/src/redux/selectors";
import { RaceCellModule } from "@tvg/ts-types/Amplitude";
import { isSeoTracksEnabled } from "../../redux/selectors";
import withApolloContext from "../../hoc/withApolloContext";
import { Props } from "./types";
import SeoFooter from "../SeoFooter";
import TracksInformationSection from "../TracksInformationSection";
import TracksLoadingMask from "../TracksLoadingMask";
import { groupTracksByState, groupTracksByCountry } from "./utils";
import { sendPageViewEvent } from "../../amplitude";
import type { ApolloContextProps } from "../../types";
import { EmptyContainer } from "./styled-component";

const TracksInformationList = ({
  fcpClient,
  showSeoFooter = false
}: Props & ApolloContextProps) => {
  const { filtered, loading } = useSeoTracks(fcpClient);

  const enableSeoRaceTracks = useSelector(isSeoTracksEnabled);
  const tabsConfig = useSelector(getTabsTracksPage);
  const isLogged = useSelector(getIsLogged);
  const accountNumber = useSelector(getAccountNumber);
  const { isFavorite, toggleFavorite } = useFavoriteWithRedux(
    isLogged,
    accountNumber,
    RaceCellModule.TIL
  );

  const navigate = useNavigate();

  const { int, usa } = useMemo(
    () =>
      filtered.reduce<Record<"usa" | "int", SeoTrack[]>>(
        (acc, track) => {
          if (track.location.country === "USA") {
            acc.usa.push(track);
          } else {
            acc.int.push(track);
          }

          return acc;
        },
        { usa: [], int: [] }
      ),
    [filtered.length]
  );

  if (!enableSeoRaceTracks) return null;

  if (loading) return <TracksLoadingMask />;

  const { tracksInformationListEmpty } = tabsConfig;

  const onTrackClick = (track: SeoTrack) => {
    const destinationUrl = `${TRACKS_PATHS.TIL}/${track.code}/${formatTrackName(
      track.name
    )}`;
    sendPageViewEvent({
      destinationUrl,
      trackName: track.name
    });
    navigate(destinationUrl);
  };

  const sharedProps = {
    onTrackClick,
    isFavorite,
    toggleFavorite
  };

  return (
    <>
      {/* CONTENT */}
      {filtered.length ? (
        <>
          <TracksInformationSection
            key="US"
            title="US"
            dictionary={groupTracksByState(usa)}
            {...sharedProps}
          />
          <TracksInformationSection
            key="International"
            title="International"
            dictionary={groupTracksByCountry(int)}
            {...sharedProps}
          />
        </>
      ) : (
        <EmptyContainer>
          <DialogMessage
            variant="info"
            title={
              tracksInformationListEmpty?.title ??
              tabsTracksPage.tracksInformationListEmpty.title
            }
            description={
              tracksInformationListEmpty?.description ??
              tabsTracksPage.tracksInformationListEmpty.description
            }
            padding="space-7"
          />
        </EmptyContainer>
      )}
      {/* FOOTER */}
      {showSeoFooter && <SeoFooter />}
    </>
  );
};

export default withApolloContext<Props>(TracksInformationList);
