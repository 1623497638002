import React from "react";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import formatCurrency from "@tvg/formatter/currency";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import { BetSelections, IconButton, Tag } from "../../../src";
import { LoadingSpinner } from "../LoadingSpinner";
import {
  Container,
  ContentActionsWrapper,
  Content,
  Actions,
  Header,
  VerticalParagraphLeft,
  VerticalParagraphRight
} from "./styled-components";
import { BetTypeCellProps, BetStatusCodesEnum } from "./types";
import { CustomBetSelectionsView } from "./components/CustomBetSelectionsView";
import { Paragraph } from "../Typography";
import { WagerCardHeader } from "../WagerCardHeader";
import { HeaderBetStatusType } from "../WagerCardHeader/types";
import OptedInTag from "./components/optedInTag";
import WagerCardNotification from "./components/Notification";
import PickBetRebet from "./components/PickBetRebet";
import PromoOnboarding from "./components/PromoOnboarding";
import WagerCardFooter from "./components/Footer";
import { buildQALabel } from "../RaceCell/utils";

const exoticBets = [
  WagerTypeCodesEnum.EXACTA,
  WagerTypeCodesEnum.TRIFECTA,
  WagerTypeCodesEnum.SUPERFECTA,
  WagerTypeCodesEnum.SUPERHIGHFIVE
];

export const BetTypeCell = ({
  numWagerableRunners,
  type,
  breed = "thoroughbred",
  betTypeName,
  selections,
  completeSelection,
  qaLabel = "bet-type-cell",
  approximatedPayout,
  betAmount,
  betStatus,
  betTicket,
  isLeg,
  scratches,
  onRepeatBetHandler,
  onCancelBetHandler,
  isKey,
  isBox,
  isWheel,
  raceNumber,
  betStatusCode,
  winAmountBet,
  showOnlySelections,
  betRefund,
  headerMyBetsProps,
  notificationProps,
  pickBetRebetProps,
  promoOnboardingProps,
  wagerCardFooterProps,
  wagerDetails,
  shouldShowRefundTag,
  racesStatus,
  onDetailsView,
  isFooterShown = false,
  isOptedIn = false,
  isMyBets = false,
  isRepeatable = true,
  enableMyBetsBehavior,
  favoriteRunnerByLeg,
  showReplacedRunners,
  ...rest
}: BetTypeCellProps) => {
  const colors = useColorTokens();
  const isExoticBet = exoticBets.includes(type);
  const isCustomSelectionsView =
    isKey || isLeg || isBox || isWheel || isExoticBet;

  const isWon = betStatusCode === BetStatusCodesEnum.WINNER;
  const isRefund = betStatusCode === BetStatusCodesEnum.REFUNDED;
  const betStatusCodeMap: Record<
    BetStatusCodesEnum,
    { label: string; value?: number | string }
  > = {
    A: {
      label: approximatedPayout !== "0" ? "APPROX. PAYOUT" : "",
      value: approximatedPayout !== "0" ? approximatedPayout : ""
    },
    R: { label: "REFUND", value: betRefund },
    L: { label: "RETURN", value: "$0.00" },
    W: { label: "WON", value: winAmountBet },
    C: { label: "REFUND", value: betRefund }
  };

  const renderRightColumn = () => {
    switch (betStatus) {
      case "PROCESSING":
        return (
          <div>
            <LoadingSpinner shouldUseColorTokens={true} />
          </div>
        );
      case "ERROR":
        return (
          <div>
            <Tag size="s" variant="warning" label="Failed" />
          </div>
        );
      case "PLACED":
        return (
          <div>
            <Tag size="s" variant="optedin" label="Placed" />
          </div>
        );
      default:
        return (
          <VerticalParagraphRight
            data-qa-label={buildQALabel(["vertical", "paragraph", "right"])}
          >
            <Paragraph
              fontFamily={isWon || isRefund ? "medium" : "regular"}
              fontWeight={isWon || isRefund ? 500 : 400}
              color={isWon ? colors.content.positive : colors.content.default}
              qaLabel={buildQALabel(["approximate", "payout", "value"])}
            >
              <>
                {
                  betStatusCodeMap[betStatusCode || BetStatusCodesEnum.ACTIVE]
                    .value
                }
              </>
            </Paragraph>
            <Paragraph
              qaLabel={buildQALabel(["approximate", "payout"])}
              fontFamily="condensedRegular"
              color={isWon ? colors.content.positive : colors.content.subtle}
              fontSize="xs"
              textAlign={isWon ? "end" : "start"}
            >
              <>
                {
                  betStatusCodeMap[betStatusCode || BetStatusCodesEnum.ACTIVE]
                    .label
                }
              </>
            </Paragraph>
          </VerticalParagraphRight>
        );
    }
  };

  const renderSelections = () =>
    isCustomSelectionsView ? (
      <CustomBetSelectionsView
        isKey={isKey}
        isBox={isBox}
        isLeg={isLeg}
        isWheel={isWheel}
        isExoticBet={isExoticBet}
        completeSelection={completeSelection}
        numWagerableRunners={numWagerableRunners}
        type={type}
        currentRaceNumber={raceNumber}
        scratches={scratches}
        breed={breed}
        racesStatus={racesStatus}
        wagerDetails={wagerDetails}
        onDetailsView={onDetailsView}
        enableMyBetsBehavior={enableMyBetsBehavior}
        favoriteRunnerByLeg={favoriteRunnerByLeg}
        showReplacedRunners={showReplacedRunners}
      />
    ) : (
      <BetSelections
        numWagerableRunners={numWagerableRunners?.[0]}
        selections={selections}
        type={type}
        breed={breed}
        showBetType={false}
        scratches={Array.from(new Set(scratches?.flat(1)))} // remove duplicates
        onHandicaping={rest.onHandicaping}
        wagerDetails={!!wagerDetails?.some((detail) => detail)}
        shouldShowRefundTag={shouldShowRefundTag}
        enableMyBetsBehavior={enableMyBetsBehavior}
        favoriteRunner={favoriteRunnerByLeg}
        showReplacedRunners={showReplacedRunners}
      />
    );

  const renderHeader = () => {
    if (isMyBets && headerMyBetsProps) {
      const {
        onApproxPayout,
        isBetWinner,
        isCanceled,
        winningsAmount,
        shouldShowPotentialReturn,
        showMinMaxWillPays,
        minWillPays,
        maxWillPays,
        onHideShowLegsGTM,
        setShowContent,
        shouldShowHideLegButton,
        showContent,
        wagerId,
        wonIllustration
      } = headerMyBetsProps;

      return (
        <WagerCardHeader
          betAmount={formatCurrency(betAmount)}
          betTypeName={betTypeName}
          betTicket={formatCurrency(betTicket)}
          betStatus={betStatus as HeaderBetStatusType}
          probableValue={approximatedPayout}
          betRefund={betRefund}
          onApproxPayout={onApproxPayout}
          isBetWinner={isBetWinner}
          isCanceled={isCanceled}
          winningsAmount={winningsAmount}
          shouldShowPotentialReturn={shouldShowPotentialReturn}
          showMinMaxWillPays={showMinMaxWillPays}
          minWillPays={minWillPays}
          maxWillPays={maxWillPays}
          onHideShowLegsGTM={onHideShowLegsGTM}
          setShowContent={setShowContent}
          shouldShowHideLegButton={shouldShowHideLegButton}
          showContent={showContent}
          wagerId={wagerId}
          wonIllustration={wonIllustration}
        />
      );
    }

    if (!showOnlySelections) {
      return (
        <Header>
          <VerticalParagraphLeft>
            <Paragraph
              qaLabel={buildQALabel(["bet", "amount"])}
            >{`${formatCurrency(betAmount)} ${betTypeName}`}</Paragraph>
            <Paragraph
              qaLabel={buildQALabel(["ticket", "amount"])}
              fontFamily="condensedRegular"
              color={colors.content.subtle}
              fontSize="xs"
            >
              {`${formatCurrency(betTicket)} TICKET`}
            </Paragraph>
          </VerticalParagraphLeft>
          {renderRightColumn()}
        </Header>
      );
    }

    return null;
  };

  const { showContent } = headerMyBetsProps || { showContent: true };

  return (
    <Container
      showOnlySelections={showOnlySelections}
      data-qa-label={buildQALabel([qaLabel])}
      {...rest}
    >
      {renderHeader()}
      {isMyBets && isOptedIn && <OptedInTag />}
      {isMyBets && notificationProps && (
        <WagerCardNotification
          {...notificationProps}
          enableMyBetsBehavior={enableMyBetsBehavior}
        />
      )}
      {isMyBets && promoOnboardingProps && (
        <PromoOnboarding {...promoOnboardingProps} />
      )}
      <ContentActionsWrapper
        data-qa-label={buildQALabel([qaLabel, "content", "actions", "wrapper"])}
      >
        {showContent ? (
          <Content
            hasButtons={!!onRepeatBetHandler || !!onCancelBetHandler}
            isMyBets={isMyBets}
            data-qa-label={buildQALabel([
              qaLabel,
              "content",
              "actions",
              "content"
            ])}
          >
            {selections?.length ? renderSelections() : null}
          </Content>
        ) : (
          <></>
        )}

        {onRepeatBetHandler || onCancelBetHandler ? (
          <Actions
            data-qa-label={buildQALabel([
              qaLabel,
              "content",
              "actions",
              "actions"
            ])}
          >
            {onRepeatBetHandler && isRepeatable && (
              <IconButton
                iconName="repeat"
                qaLabel={buildQALabel(["repeat", "bet", "btn"])}
                size="s"
                variant="tertiary"
                tertiaryColor={colors.content.subtle}
                onPress={onRepeatBetHandler}
              />
            )}
            {onCancelBetHandler && (
              <IconButton
                iconName="trash"
                qaLabel={buildQALabel(["cancel", "bet", "btn"])}
                size="s"
                variant="tertiary"
                tertiaryColor={colors.content.subtle}
                onPress={onCancelBetHandler}
              />
            )}
          </Actions>
        ) : null}
      </ContentActionsWrapper>
      {isMyBets && pickBetRebetProps && <PickBetRebet {...pickBetRebetProps} />}
      {isMyBets && isFooterShown && wagerCardFooterProps && (
        <WagerCardFooter {...wagerCardFooterProps} />
      )}
    </Container>
  );
};

export default BetTypeCell;
export { BetStatusCodesEnum };
