import React from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { getIsLogged, getOptedInPromos } from "@urp/store-selectors";
import { Race } from "@tvg/ts-types/Race";
import {
  hasPromoAvailable,
  isUserOptedInPromo,
  getTrackName
} from "@urp/lib-racetracks/src/utils/races";
import {
  getActiveBets,
  getCurrentTabTracksPage
} from "@urp/lib-racetracks/src/redux/selectors";
import buildRaceUrl from "@tvg/formatter/url";
import { RaceCellModule as Module } from "@tvg/ts-types/Amplitude";
import { RaceCell } from "@tvg/design-system/web/components/RaceCell";
import { sendRaceCellClickedAnalyticEvt } from "@urp/lib-racetracks/src/utils/analytics";
import { useRaceTracksFilter } from "@urp/lib-racetracks/src";
import { useMediaQuery, breakpoints } from "@tvg/design-system";
import { Element } from "./styled-components";

const renderRacesList = (races: Race[], qaLabel: string) => {
  const isUserLogged = useSelector(getIsLogged);
  const optedInPromos = useSelector(getOptedInPromos);
  const activeBets = useSelector(getActiveBets);
  const currentTabTracksPage = useSelector(getCurrentTabTracksPage);
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);
  const { getCurrentActiveFilters } = useRaceTracksFilter();

  return races.map((race: Race) => {
    const availableRacePromos = hasPromoAvailable(race);
    const isOptedIn =
      isUserLogged && isUserOptedInPromo(optedInPromos, availableRacePromos);
    const raceUrl = buildRaceUrl(
      race.track.code,
      race.track.name,
      +race.number
    );
    return (
      <Element key={race.id}>
        <RaceCell.Details
          race={race}
          to={raceUrl}
          numberActiveBets={get(activeBets, race?.tvgRaceId ?? "", 0)}
          qaLabel={`${qaLabel}-${race.id}`}
          isOptedIn={isOptedIn}
          hasPromosAvailable={!isOptedIn && !!availableRacePromos.length}
          onClick={() =>
            sendRaceCellClickedAnalyticEvt({
              module: Module.UPCOMING_RACES,
              trackName: getTrackName(race.track.name),
              raceNumber: race.number,
              linkUrl: raceUrl,
              promoOptedIn: isOptedIn ? "true" : "none",
              trackHasPromos: !!race.track.hasAboveTheLinePromo,
              raceHasPromos: !!availableRacePromos.length,
              isFDTV: race.video.onTvg || race.video.onTvg2,
              trackCountry: race.track.location?.country || "",
              tabActive: isDesktop ? currentTabTracksPage : undefined,
              activeFilters: isDesktop ? getCurrentActiveFilters() : undefined
            })
          }
        />
      </Element>
    );
  });
};

export default renderRacesList;
