import { Store } from "@reduxjs/toolkit";
import parseCAPIMessage from "@tvg/utils/capiUtils";
import { get } from "lodash";

export const getUpcomingRacesInfo = (store: Store) =>
  parseCAPIMessage(store, "capi.messages.tracksUpcomingRaces", {
    title: "Upcoming Races",
    noUpcomingRaces: {
      title: "No Upcoming Races",
      hint: "Still no upcoming races availables"
    },
    noUpcomingRacesDesk: {
      title: "Upcoming Races",
      hint: "No upcoming races available with selected filters"
    },
    maxResults: 20
  });

export const getDateRace = (store: Store): string =>
  get(store, "mtpStatus.raceDate", "");

export const getMtpStatus = (store: Store) =>
  get(store, "mtpStatus.mtpStatus", []);
