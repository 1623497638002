import type { Config } from "./types";
import { isBrowser, isRN, getServiceHost } from "./utils";
import { ServiceConfig } from "./types";

export default {
  config(envConfig: Config) {
    const serviceHost = getServiceHost(envConfig);

    const service: Record<string, string> = {};
    const servicePort = 8080;
    Object.keys(envConfig.service).forEach((key) => {
      const serviceName = envConfig.serviceName[key as keyof ServiceConfig];
      const servicePath = envConfig.service[key as keyof ServiceConfig];
      service[key] =
        isBrowser() || isRN()
          ? // not proud but for now we can live with this for cosmo instead of a major refactor
            `${
              key.includes("cosmo")
                ? serviceHost
                    .replace("service", "api")
                    // pregaria da avo ana mas a vida é dura
                    .replace("api.us.betfair.com", "tvg-api.us.betfair.com")
                    .replace(
                      "api-qa.us.betfair.com",
                      "tvg-api-qa.us.betfair.com"
                    )
                    .replace(
                      "api-staging.us.betfair.com",
                      "tvg-api-staging.us.betfair.com"
                    )
                    // again not proud but life is hard so this qa domain
                    .replace("racing.qa.fndl.dev", "api.racing.qa.fndl.dev")
                : serviceHost
            }${servicePath}`
          : `http://${serviceName}:${servicePort}${servicePath}`;
    });

    return {
      service_host: serviceHost,
      service,
      google: envConfig.google,
      amplitude: envConfig.amplitude,
      domain: {
        desktop: envConfig.domain.desktop,
        mobile: envConfig.domain.mobile,
        talentPicks: envConfig.domain.talentPicks,
        accountWallet: envConfig.domain.accountWallet,
        equibase: envConfig.domain.equibase
      },
      externalDomain: envConfig.externalDomain,
      externalServices: envConfig.externalServices,
      clientId: envConfig.clientId,
      brazeId: envConfig.brazeId,
      perimeterxId: envConfig.perimeterxId,
      paypalID: envConfig.paypalID,
      apptentiveID: envConfig.apptentiveID,
      apptentiveTvg5: envConfig.apptentiveTvg5,
      storyblokToken: envConfig.storyblokToken,
      storyblokVersion: envConfig.storyblokVersion,
      placesAPI: envConfig.placesAPI,
      sonarQube: envConfig.sonarQube,
      datadogRum: envConfig.datadogRum,
      siftAccountId: envConfig.siftAccountId,
      siftApiKey: envConfig.siftApiKey,
      siftBeaconKey: envConfig.siftBeaconKey,
      appTentiveIos: envConfig.appTentiveIos,
      appTentiveAndroid: envConfig.appTentiveAndroid,
      appTentiveWeb: envConfig.appTentiveWeb,
      recaptchaID: envConfig.recaptchaID
    };
  }
};
