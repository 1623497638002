import { ElementType } from "react";
import loadable from "@loadable/component";
import getPublicPath from "../utils/getPublicPath";
// Layouts
import MainLayout from "../layouts/MainLayout";
import WagerpadLayout from "../layouts/WagerpadLayout";

const publicPath = getPublicPath();

if (publicPath) {
  // @ts-ignore
  __webpack_public_path__ = publicPath;
}

// Components
const Home = loadable(
  () => import(/* webpackChunkName: 'home' */ "../pages/Home")
);
const Live = loadable(
  () => import(/* webpackChunkName: 'live' */ "../pages/Live")
);
const Registration = loadable(
  () => import(/* webpackChunkName: 'registration' */ "../pages/Registration")
);
const Handicapping = loadable(
  () => import(/* webpackChunkName: 'handicapping' */ "../pages/Handicapping")
);
const Promos = loadable(
  () => import(/* webpackChunkName: 'promos' */ "../pages/Promos")
);
const WagerRewards = loadable(
  () => import(/* webpackChunkName: 'wagerRewards' */ "../pages/WagerRewards")
);
const Referral = loadable(
  () => import(/* webpackChunkName: 'referral' */ "../pages/Referral")
);

const ProgramPage = loadable(
  () => import(/* webpackChunkName: 'programPage' */ "../pages/ProgramPage")
);
const Empty = loadable(
  () => import(/* webpackChunkName: 'empty' */ "../pages/Empty")
);
const Preferences = loadable(
  () => import(/* webpackChunkName: 'preferences' */ "../pages/Preferences")
);
const Unsubscribe = loadable(
  () => import(/* webpackChunkName: 'unsubscribe' */ "../pages/Unsubscribe")
);
const MyBets = loadable(
  () => import(/* webpackChunkName: 'myBets' */ "../pages/MyBets")
);
const CredentialsReset = loadable(
  () =>
    import(
      /* webpackChunkName: 'credentialsReset' */ "../pages/CredentialsReset"
    )
);
const Wallet = loadable(
  () => import(/* webpackChunkName: 'wallet' */ "../pages/Wallet")
);
const ResponsibleGaming = loadable(
  () =>
    import(
      /* webpackChunkName: 'responsibleGaming' */ "../pages/ResponsibleGaming"
    )
);
const PrivacyPolicy = loadable(
  () => import(/* webpackChunkName: 'privacyPolicy' */ "../pages/PrivacyPolicy")
);
const CaliforniaPrivacyPolicy = loadable(
  () =>
    import(
      /* webpackChunkName: 'californiaPrivacyPolicy' */ "../pages/CaliforniaPrivacyPolicy"
    )
);
const Accessibility = loadable(
  () => import(/* webpackChunkName: 'accessibility' */ "../pages/Accessibility")
);

const Racetracks = loadable(
  () => import(/* webpackChunkName: 'accessibility' */ "../pages/Racetracks")
);

const TracksPage = loadable(
  () => import(/* webpackChunkName: 'accessibility' */ "../pages/TracksPage")
);

const WagerPad = loadable(
  () => import(/* webpackChunkName: 'urpWagerPad' */ "../pages/WagerPad")
);

export const layoutMap: { [key: string]: ElementType } = {
  mainLayout: MainLayout,
  wagerpadLayout: WagerpadLayout
};

export const componentMap: { [key: string]: ElementType } = {
  home: Home,
  registration: Registration,
  promos: Promos,
  handicapping: Handicapping,
  races: Empty,
  wagerRewards: WagerRewards,
  referral: Referral,
  tracks: TracksPage,
  results: Empty,
  racetracks: Racetracks,
  greyhounds: Empty,
  programPage: ProgramPage,
  preferences: Preferences,
  unsubscribe: Unsubscribe,
  credentialsReset: CredentialsReset,
  accountSummary: Empty,
  myBets: MyBets,
  myFunds: Empty,
  myFundsDeposit: Empty,
  myFundsWithdraw: Empty,
  wallet: Wallet,
  myStable: Empty,
  myFavoriteTrack: Empty,
  myProfile: Empty,
  yearEndReports: Empty,
  w2gReports: Empty,
  live: Live,
  livePage: Live,
  responsibleGaming: ResponsibleGaming,
  termsAndConditions: Empty,
  privacyPolicy: PrivacyPolicy,
  specificPrivacyRights: CaliforniaPrivacyPolicy,
  wagerResponsibly: Empty,
  accessibility: Accessibility,
  wagerPad: WagerPad,
  empty: Empty
};

/** Preload most important pages * */
ProgramPage.preload();
Home.preload();
