import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RaceCell, Icon } from "@tvg/design-system/web";
import buildRaceUrl from "@tvg/formatter/url";
import { Race, RaceStatusEnum, Promo } from "@tvg/ts-types/Race";
import {
  getAccountNumber,
  getIsLogged,
  getOptedInPromos as getOptedInPromosStore
} from "@urp/store-selectors/users";
import getOptedInPromos from "@tvg/utils/PromoUtils";

import {
  getActiveBets,
  getOpenTracks,
  getCurrentTabTracksPage
} from "@urp/lib-racetracks/src/redux/selectors";
import { sendRaceCellClickedAnalyticEvt } from "@urp/lib-racetracks/src/utils/analytics";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { get } from "lodash";
import { isTrackOpen } from "@urp/lib-racetracks/src/utils/races";
import { useRaceTracksFilter } from "@urp/lib-racetracks/src";
import Collapsible from "../Collapsible";
import CollapsibleSummary from "../CollapsibleSummary";
import { Props } from "./types";
import { getWidthCells } from "../../utils";
import { LETTER_BLOCK, LIST, RACE } from "../../utils/constants";
import { ScrollButton, RaceListContainer } from "./styled-components";
import { SINGLE_NAVIGATION } from "./constants";

const CollapsibleWrapper = ({
  track,
  onTrackClickHandler,
  module,
  qaLabel = "",
  checkRacePromos
}: Props) => {
  const isUserLogged = useSelector(getIsLogged);
  const accountNumber = useSelector(getAccountNumber);
  const optedInPromos = useSelector(getOptedInPromosStore);
  const openTracks = useSelector(getOpenTracks);
  const activeBets = useSelector(getActiveBets);
  const currentTabTracksPage = useSelector(getCurrentTabTracksPage);
  const races = track.races as Race[] | undefined;
  const isOpen = isTrackOpen(openTracks, track.code) && !!races;
  const [activeIdx, setActiveIdx] = useState(0);
  const [isNextBtnEnabled, setIsNextBtnEnabled] = useState(true);
  const racesRef = useRef<HTMLDivElement | null>(null);
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);
  const { getCurrentActiveFilters } = useRaceTracksFilter();

  useEffect(() => {
    if (isOpen) {
      const activeIdxIni = (races || []).findIndex(
        (race) =>
          race.status &&
          [RaceStatusEnum.OPEN, RaceStatusEnum.UP_NEXT].includes(
            race.status.code
          )
      );

      setActiveIdx(activeIdxIni >= 0 ? activeIdxIni : (races || []).length);
    }
  }, [isOpen]);

  useEffect(() => {
    setTimeout(() => {
      racesRef.current?.scroll({
        top: 0,
        left: getWidthCells(activeIdx, racesRef.current?.childNodes, 2),
        behavior: "smooth"
      });
    }, 250);
  }, [isOpen, activeIdx]);

  useEffect(() => {
    setIsNextBtnEnabled(
      getWidthCells(activeIdx, racesRef.current?.childNodes, 2) +
        (racesRef.current?.clientWidth || 0) <
        (racesRef.current?.scrollWidth || Infinity)
    );
  }, [
    isOpen,
    activeIdx,
    racesRef,
    racesRef.current?.scrollWidth,
    races?.length
  ]);

  /**
   * Counts the number of elements that fit in the width of the parent
   *
   * @returns Number of visible elements
   */
  const calculateVisibleElements = () => {
    const scrollWidth = racesRef.current?.clientWidth ?? 0;
    let elementsToNavigate = 0;
    let accWidth = 0;
    racesRef.current?.childNodes.forEach((el) => {
      accWidth += +getComputedStyle(el as Element).width.replace("px", "");

      if (accWidth < scrollWidth) elementsToNavigate += SINGLE_NAVIGATION;
    });

    return elementsToNavigate;
  };

  const isPrevBtnEnabled = activeIdx > 0;
  const pivot = calculateVisibleElements();

  return (
    <Collapsible
      key={track.code}
      summary={
        <CollapsibleSummary
          trackCode={track.code}
          trackName={track.name}
          isResulted={track.isFinished}
          isUserLogged={isUserLogged}
          hasPromo={track.hasAboveTheLinePromo}
          accountNumber={accountNumber}
          qaLabel={qaLabel}
          module={module}
        />
      }
      isOpen={isOpen}
      onClick={() => onTrackClickHandler(track.code)}
      qaLabel={buildQaLabel([qaLabel, track.code])}
    >
      {isDesktop && (
        <ScrollButton
          data-qa-label={buildQaLabel([qaLabel, "prev", "button"])}
          onClick={() => setActiveIdx((prev) => prev - pivot)}
          disabled={!isPrevBtnEnabled}
          hasMarginLeft
        >
          <Icon name="arrowLeft" size="s" />
        </ScrollButton>
      )}

      <RaceListContainer
        ref={racesRef}
        hasScrollButtons={isDesktop}
        data-qa-label={buildQaLabel([qaLabel, RACE, LIST])}
      >
        {isOpen &&
          races?.map((race: Race) => {
            const promo: Promo | null = getOptedInPromos(
              race.promos || [],
              optedInPromos,
              isUserLogged
            );

            const raceUrl = buildRaceUrl(
              race.track.code,
              race.track.name,
              +race.number
            );

            const { hasPromoAvailable, isOptedInPromo } = checkRacePromos(
              race.promos
            );

            return (
              <RaceCell.Summary
                key={race.id}
                race={race}
                qaLabel={buildQaLabel([qaLabel, LETTER_BLOCK, race.id])}
                to={raceUrl}
                hasPromosAvailable={hasPromoAvailable}
                isOptedIn={isOptedInPromo}
                numberActiveBets={get(activeBets, race?.tvgRaceId ?? "", 0)}
                onClick={() =>
                  sendRaceCellClickedAnalyticEvt({
                    module,
                    trackName: race.track.name,
                    raceNumber: race.number,
                    linkUrl: raceUrl,
                    promoOptedIn: isOptedInPromo ? "true" : "false",
                    trackHasPromos: track.hasAboveTheLinePromo,
                    raceHasPromos: !!promo,
                    isFDTV: race.video.onTvg || race.video.onTvg2,
                    trackCountry: track.location?.country || "",
                    tabActive: currentTabTracksPage,
                    activeFilters: isDesktop
                      ? getCurrentActiveFilters()
                      : undefined
                  })
                }
              />
            );
          })}
      </RaceListContainer>

      {isDesktop && (
        <ScrollButton
          data-qa-label={buildQaLabel([qaLabel, "next", "button"])}
          onClick={() => setActiveIdx((prev) => prev + pivot)}
          disabled={!isNextBtnEnabled}
          hasMarginRight
        >
          <Icon name="arrowRight" size="s" />
        </ScrollButton>
      )}
    </Collapsible>
  );
};

export default memo(CollapsibleWrapper);
