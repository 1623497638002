import { useState } from "react";
import { useSelector } from "react-redux";
import { getFiltersConfiguration } from "@urp/tracks-builder/src/redux/selectors";
import { SupportedFilters } from "@urp/tracks-builder/src/types";
import { RaceTypeCodeEnum } from "@tvg/ts-types/Race";
import {
  RACE_TYPE_OPTIONS,
  REGION_OPTIONS,
  TRACKS_OPTIONS,
  DISTANCE_OPTIONS,
  BETTING_FEATURE_OPTIONS
} from "@urp/tracks-builder/src/components/TracksFilter/constants";
import { sendTracksFilterAnalyticEvt } from "@urp/lib-racetracks/src/utils/analytics";
import { FilterName, FilterType, TrackFilterValueEnum, TABS } from "../types";

import useRaceTracksFilter from "./useRaceTracksFilter";

type FilterValues = string[] | RaceTypeCodeEnum[] | TrackFilterValueEnum[];

type SelectedFilters = {
  [K in SupportedFilters]: FilterValues;
};

const useDrawer = () => {
  const initialState = {
    [SupportedFilters.RACE_TYPE]: [],
    [SupportedFilters.REGION]: [],
    [SupportedFilters.TRACK_TYPE]: [],
    [SupportedFilters.BETTING_FEATURE]: [],
    [SupportedFilters.DISTANCE]: []
  };

  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>(initialState);

  const filtersConfig = useSelector(getFiltersConfiguration);

  const {
    raceTypesFilter,
    regionsFilter,
    trackTypesFilter,
    racesFilter,
    distancesFilter,
    handleRaceTypeChange,
    handleRegionChange,
    handleDistanceFilter,
    handleRaceFilter,
    handleTrackChange,
    handleClearFilters
  } = useRaceTracksFilter();

  const handlersMap: {
    [key: string]: (value: string[], currentTab: TABS) => void;
  } = {
    [SupportedFilters.RACE_TYPE]: handleRaceTypeChange,
    [SupportedFilters.REGION]: handleRegionChange,
    [SupportedFilters.TRACK_TYPE]: handleTrackChange,
    [SupportedFilters.BETTING_FEATURE]: handleRaceFilter,
    [SupportedFilters.DISTANCE]: handleDistanceFilter
  };

  const filterOptionsMap = {
    [SupportedFilters.RACE_TYPE]: RACE_TYPE_OPTIONS,
    [SupportedFilters.REGION]: REGION_OPTIONS,
    [SupportedFilters.TRACK_TYPE]: TRACKS_OPTIONS,
    [SupportedFilters.BETTING_FEATURE]: BETTING_FEATURE_OPTIONS,
    [SupportedFilters.DISTANCE]: DISTANCE_OPTIONS
  };

  const activeFiltersMap = {
    [SupportedFilters.RACE_TYPE]: raceTypesFilter,
    [SupportedFilters.REGION]: regionsFilter,
    [SupportedFilters.TRACK_TYPE]: trackTypesFilter,
    [SupportedFilters.BETTING_FEATURE]: racesFilter,
    [SupportedFilters.DISTANCE]: distancesFilter
  };

  const filterGroups = filtersConfig.map((config) => {
    const key = config.id;
    const { label } = config;

    return {
      key,
      label,
      options: filterOptionsMap[key],
      selectedValues: activeFiltersMap[key]
    };
  });

  const onChangeFilter = (key: SupportedFilters, value: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [key]: [...value]
    }));
  };

  const onClearFilters = () => {
    setSelectedFilters(initialState);
    handleClearFilters();
  };

  const onApplyFilters = (currentTab: TABS) => {
    const allFilters = [selectedFilters, activeFiltersMap];
    const mergedFilters: SelectedFilters = initialState;

    allFilters.forEach((obj: SelectedFilters) => {
      Object.entries(obj).forEach(([key, value]) => {
        // @ts-ignore
        mergedFilters[key] = (mergedFilters[key] || []).concat(value);
      });
    });

    sendTracksFilterAnalyticEvt({
      module: "tracks-filter",
      filterType: FilterType.ALL_FILTERS,
      filterName: Object.entries(mergedFilters)
        /* eslint-disable @typescript-eslint/no-unused-vars */
        .filter(([_, value]) => value.length > 0)
        .map(([key]) => key.toLowerCase()) as FilterName[],
      filterValue: Object.values(mergedFilters).reduce(
        // @ts-ignore
        (acc, val) => acc.concat(val),
        []
      )
    });

    return Object.entries(mergedFilters).forEach(([filter, values]) => {
      handlersMap[filter](values, currentTab);
    });
  };

  const hasFiltersApplied = Object.values(selectedFilters).some(
    (filter) => filter.length > 0
  );

  return {
    filterGroups,
    hasFiltersApplied,
    onChangeFilter,
    onClearFilters,
    onApplyFilters
  };
};

export default useDrawer;
